
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import MainBanner from "@/components/MainBanner.vue";
import TitleText from "@/components/TitleText.vue";
import JobVacanciesList from "@/components/JobVacanciesList.vue";
import Form from "@/components/Form.vue";
import ApiService from "@/services/ApiService";
import { JoinUsForm as IJoinUsForm } from "@/interfaces/JoinUsForm.interface";

@Component<any>({
  metaInfo() {
    return {
      title: "Job Vacancies at Leighton - Tech, Talent, Together",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `${this.banner.text}`
        }
      ]
    };
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    JobVacanciesList,
    Form
  }
})
export default class CurrentVacancies extends Vue {
  private banner = {
    heading: "Looking for your next role in",
    emphasis: "tech?",
    text:
      "Looking for your next role in tech? We’d love to help you become the best version of yourself",
    action: {
      text: "Join our mission"
    },
    image: require("@/assets/banner-images/join-us.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title: "Join us and make a difference.",
    subTitle: `Our purpose is simple. <span class="emphasise--dark">We make the improbable</span>, possible in everything we do. Why? Leighton are on a mission to help businesses and their customers thrive – this is not simple. To do this we need go getters and thinkers, we need Agile Delivery Managers and Technologists. We need people who want to make a difference and will go the extra mile to deliver for our customers.`
  };

  private titleText2 = {
    customClass: "mt-md",
    title: "Open Roles",
    subTitle:
      "From Architects and Agile Delivery Managers to Software Engineers and UI/UX Designers we are always looking for the best talent."
  };

  private jobVacanciesCustomClass = "mt-md";

  private titleText3 = {
    customClass: "background--grey mt-md pt-md",
    subTitle: `
      If you have any questions about any of our opportunities, please <span class="emphasise--dark">fill out the form below</span> and we’ll happily answer any questions you have.
      <br><br>
      We’re always keen to hear from amazing people, if there isn’t a role for you just yet, send us your CV anyway. You never know what the future holds. Please <span class="emphasise--dark">attach your CV</span> and the type of opportunity you're interested in to the <span class="emphasise--dark">form below</span>.
    `
  };

  private form = {
    success: false,
    error: false,
    popUpMsg: ``,
    customClass: "background--grey py-md",
    formHeading: "Get in touch",
    formData: {
      name: {
        value: "",
        errMsg: "Please enter a valid name",
        error: false
      },
      email: {
        value: "",
        errMsg: "Please enter your email address",
        error: false
      },
      phone: {
        value: "",
        errMsg: "Please enter a valid phone number",
        error: false
      },
      message: {
        value: "",
        errMsg:
          "Please enter a minimum of 10 characters and maximum of 4000 characters",
        error: false
      },
      subject: {
        value: "",
        errMsg: "Please select an item from the dropdown",
        error: false
      },
      fileUpload: {
        value: [],
        errMsg: "There was an error uploading your file",
        error: false
      },
      fileNames: []
    },
    rows: [
      [
        {
          label: "Name",
          name: "name",
          type: "text",
          model: "name",
          required: true,
          disableSpace: false
        }
      ], //Row 1
      [
        {
          label: "Email Address",
          name: "email",
          type: "email",
          model: "email",
          required: true,
          disableSpace: true
        },
        {
          label: "Telephone Number",
          name: "phone",
          type: "tel",
          model: "phone",
          required: true,
          disableSpace: true
        }
      ], //row 2
      [
        {
          label: "Message",
          name: "message",
          type: "textarea",
          model: "message",
          required: true,
          rows: 5,
          cols: 50,
          disableSpace: false
        }
      ], //Row 3
      [
        {
          label: "Upload your CV here",
          name: "file",
          type: "file",
          model: "fileUpload",
          required: false,
          accept: ".doc, .docx, .pdf,  application/msword, application/pdf"
        }
      ] //Row 4
    ]
  };

  private regex = /(<([^>]+)>)/gi;

  private async sendForm(formData: IJoinUsForm) {
    formData.name.value = formData.name.value.replace(this.regex, "");
    formData.email.value = formData.email.value.replace(this.regex, "");
    formData.phone.value = formData.phone.value.replace(this.regex, "");
    formData.subject.value = 'Leighton Vacancies Form';
    formData.message.value = formData.message.value.replace(this.regex, "");

    // reset each error so that if its the users second attempt, the error does not stay visible upon success
    this.form.formData.name.error = false;
    this.form.formData.email.error = false;
    this.form.formData.phone.error = false;
    this.form.formData.subject.error = false;
    this.form.formData.message.error = false;

    const files = Array.from(formData.fileUpload.value);
    if (files.length > 0) {
      files.forEach(file => {
        formData.fileNames.push(file.name);
      });
    }

    const response = await ApiService.post(
      "https://g623sdseed.execute-api.eu-west-2.amazonaws.com/dev/submitted",
      formData
    );
    if (response.has_error) {
      if (response.messages.name.status === true) {
        this.form.formData.name.error = true;
      } else {
        this.form.formData.name.error = false;
      }
      if (response.messages.email.status === true) {
        this.form.formData.email.error = true;
      } else {
        this.form.formData.email.error = false;
      }
      if (response.messages.phone.status === true) {
        this.form.formData.phone.error = true;
      } else {
        this.form.formData.phone.error = false;
      }
      if (response.messages.subject.status === true) {
        this.form.formData.subject.error = true;
      } else {
        this.form.formData.subject.error = false;
      }
      if (response.messages.message.status === true) {
        this.form.formData.message.error = true;
      } else {
        this.form.formData.message.error = false;
      }
    } else {
      if (files.length > 0) {
        if (response.fileUploadUrl.length === 0) {
          this.form.formData.fileUpload.error = true;
        }
        for (let i = 0; i < response.fileUploadUrl.length; i++) {
          await ApiService.put(response.fileUploadUrl[i], files[i])
            .then(() => {
              this.form.popUpMsg = `
                <p>
                  Thank you for your message.
                </p>
                <p>
                  A member of the team will be in touch shortly.
                </p>
              `;
              this.form.error = false;
              this.form.success = true;
            })
            .catch(() => {
              this.form.popUpMsg = `
              <p>
                There was a problem uploading your file(s).
              </p>
            `;
              this.form.success = false;
              this.form.error = true;
            });
        }
      } else {
        this.form.error = false;
        this.form.success = true;
        this.form.popUpMsg = `
          <p>
            Thank you for your message.
          </p>
          <p>
            A member of the team will be in touch shortly.
          </p>
        `;
      }
    }
  }
  private closePopUp(): void {
    // if error message is shown, dont reset form when message is closed
    if (this.form.error) {
      this.form.error = false;
    } else {
      // if success message is shown reset the form when message is closed
      this.form.formData.name.value = "";
      this.form.formData.email.value = "";
      this.form.formData.phone.value = "";
      this.form.formData.subject.value = "";
      this.form.formData.message.value = "";
      this.form.formData.fileNames = [];
      this.$refs.fileInput = [];
      this.form.success = false;
    }
  }
}
